<template>
  <div class="container-top" v-if="detail">
    <div class="pc">
      <div class="container padding-top-60">
        <div class="product-wrapper">
          <!-- 상품정보 좌 -->
          <div class="card-img" :style="imgField(detail.img, 'pc')"></div>
          <!-- 상세정보 우 -->
          <div>
            <!-- 파트너 -->
            <div class="flex-align margin-top-12" v-if="detail.partner">
              <img style="width:32px;height:32px"
                   :src="detail.partner.img" v-if="detail.partner.img">
              <div class="body4 sub3 margin-left-8">{{ detail.partner.name }}</div>
            </div>
            <div class="subtitle3 main padding-top-8">{{ detail.title }}</div>
            <div class="body3 padding-top-8">{{ detail.content }}</div>
            <div class="lp-divider-gray1 padding-top-24"></div>
            <div class="flex-between padding-top-24" v-if="detail.price.price_type===0">
              <div class="h7">총 결제금액</div>
              <div class="h4">{{ discountedPrice | currency }}</div>
            </div>
            <div class="padding-top-24 flex-end" v-else>
              <div class="h7">{{ detail.price.price_text }}</div>
            </div>
            <div class="padding-top-40">
              <button class="button is-primary body2-bold" style="width:100%;height:52px" @click="clickInquiry">1:1 문의하기</button>
            </div>
          </div>
        </div>
        <!-- 상품 상세 -->
        <!-- 탭 -->
        <tab :menu="[{id:0,name:'서비스 안내'},{id:1,name:'이용문의'}]" style="margin-top:100px">
          <template v-slot:tab0>
            <div v-if="detail.imgs && detail.imgs.length>0" style="margin-top:64px;padding:0 75px">
              <div class="content-slide" v-if="detail.imgs">
                <slick ref="slick" :options="slickOptions">
                  <div v-for="(img, idx) in detail.imgs"
                       :key="'slide-'+idx" class="slick-img-outer">
                    <img :src="img" alt="" class="slick-img" :style="slickImg">
                  </div>

                </slick>
              </div>
            </div>
            <div class="sun-editor-editable content column is-12" id="anchor0" v-html="detail.info">{{ detail.info }}</div>
          </template>
          <template v-slot:tab1>
            <div class="content column is-12" style="margin-top:64px">
              <product-inquiry></product-inquiry>
            </div>
          </template>
        </tab>
      </div>
    </div>

    <div class="mobile">
      <div class="card-img" :style="imgField(detail.img, 'mobile')"></div>
      <div class="container padding-top-16">
        <!-- 파트너 -->
        <div class="flex-align" v-if="detail.partner">
          <img style="width:24px;height:24px"
               :src="detail.partner.img" v-if="detail.partner.img">
          <div class="body5 sub3 margin-left-4">{{ detail.partner.name }}</div>
        </div>
        <div class="subtitle5 main" style="margin-top:6px">{{ detail.title }}</div>
        <div class="padding-top-8 sub2">{{ detail.content }}</div>
      </div>
      <!-- 상품 상세-->
      <div>
        <div class="lp-divider-gray3 margin-top-12"></div>
        <!-- 탭 -->
        <tab :menu="[{id:0,name:'서비스 안내'},{id:1,name:'이용문의'}]">
          <template v-slot:tab0>
            <div v-if="detail.imgs && detail.imgs.length>0" class="content column is-12">
              <div class="content-slide" v-if="detail.imgs">
                <scroll-wrapper>
                  <div class="flex-align">
                    <template v-for="(img, idx) in detail.imgs">
                      <img :src="img" alt="" class="slick-img" style="width:260px;height:385px;margin:0 6px" :key="'slide-'+idx">
                    </template>
                  </div>
                </scroll-wrapper>
                <!--<slick ref="slick" :options="slickOptions">
                  <div v-for="(img, idx) in detail.imgs"
                       :key="'slide-'+idx" class="slick-img-outer">
                    <img :src="img" alt="" class="slick-img" :style="slickImg">
                  </div>
                </slick>-->
              </div>
            </div>
            <div v-html="detail.info" class="sun-editor-editable padding-left-16 padding-right-16">{{ detail.info }}</div>
          </template>
          <template v-slot:tab1>
            <product-inquiry></product-inquiry>
          </template>
        </tab>
      </div>
      <div class="fixed-bottom">
        <button class="button is-primary body2-bold" style="width:100%;height:48px" @click="clickInquiry">1:1 문의하기</button>
      </div>
    </div>
  </div>

</template>

<script>
  import Slick from 'vue-slick'
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import ProductInquiry from "./ProductInquiry";
  import Tab from "../module/Tab";
  import ScrollWrapper from "../module/ScrollWrapper";

  export default {
    name: "StoreDetail",
    mixins: [
      UserAPIMixin
    ],
    components: {
      ScrollWrapper,
      Tab,
      ProductInquiry,
      Slick
    },
    data() {
      return {
        // 상세 상품
        detail: undefined,
        slickOptions: {
          centerMode: false,
          variableWidth: false,
          infinite: true,
          autoplay: false,
          centerPadding:'20px',
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 600,
          prevArrow: '<div class="arrow-left"><img class="arrow-img" src="/static/icon/fi_chevron-left.svg" @click="prev"></div>',
          nextArrow: '<div class="arrow-right"><img class="arrow-img" src="/static/icon/fi_chevron-right.svg" @click="next"></div>',
          responsive: [
            {
              breakpoint: 767,
              settings: {
                autoplay: true,
                slidesToShow: 1,
                centerMode: true,
                dots: false,
                arrows: false
              }
            }
          ]
        },
        guide: [
          {
            title: '디자인 스킨 선택',
            content: '디자인 마켓에서 내 주문전환 방식에 맞는 디자인 스킨을 선택합니다.'
          },
          {
            title: '옵션 선택하기',
            content: '나의 사업 모델에 적합한 상품 옵션을 선택합니다. '
          },
          {
            title: '부가 서비스 선택 및 결제',
            content: '선택한 테마의 결제 방식과 부가서비스 이용 여부를 선택하고 결제합니다. 디자인 스킨 수정을 선택하지 않은 고객은 STEP 4,5를 건너뛰셔도 됩니다. '
          },
          {
            title: '담당자 배치와 수정 의견서 작성 (수정 옵션 선택 시)',
            content: '‘디자인 스킨 + 부분 수정 개발 요청’ 옵션을 선택한 고객의 경우, 결제 후 서비스 신청이 접수되면 담당자가 배치됩니다. 담당자가 제시하는 가이드에 따라 수정 의견서를 작성합니다. '
          },
          {
            title: '디자인 컨펌 (수정 옵션 선택 시)',
            content: '수정 제안서의 디자인, 개발 요소를 검토합니다. '
          },
          {
            title: '개발 진행',
            content: '검수가 완료된 디자인 시안을 바탕으로 개발에 착수합니다. '
          },
          {
            title: '내 서비스 신청 완료!',
            content: '런치팩과의 커뮤니케이션으로 지속적인 업데이트가 가능합니다. '
          }
        ]
      }
    },
    watch:{
      '$route'(n){
        if(n.query.id) {
          this.getProductDetail();
        }
      }
    },
    created() {
      if(this.$route.query.id) {
        this.getProductDetail();

        if(this.isMobile) {
          this.slickOptions.variableWidth = false;
          this.slickOptions.centerMode = false;
          this.slickOptions.autoplay = true;
        }
      }
    },
    /*beforeDestroy() {
      document.getElementById('ch-plugin').style.display = '';
    },*/
    /*mounted () {
      setTimeout(()=>{
        document.getElementById('ch-plugin').style.display = 'none';
      },1000);
    },*/
    computed: {
      isDiscount() {
        if (this.detail.price.is_discount && this.detail.price.discount_start !== null && this.detail.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return !(currentDate < this.detail.price.discount_start || currentDate > this.detail.price.discount_end);
        } else if (this.detail.price.discount_start !== null && this.detail.price.discount_end === null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate >= this.detail.price.discount_start;
        } else if (this.detail.price.discount_start === null && this.detail.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate <= this.detail.price.discount_end;
        } else {
          return true;
        }
      },
      discountedPrice() {
        let total = 0;
        if (this.isDiscount) {
          if (this.detail.price.discount_rate !== 0 && this.detail.price.discount_type === 0) {
            total = this.detail.price.price * (1 - (this.detail.price.discount_rate / 100))
          } else if (this.detail.price.discount_price !== 0 && this.detail.price.discount_type === 1) {
            total = this.detail.price.price - this.detail.price.discount_price;
          } else {
            total = this.detail.price.price;
          }
        } else total = this.detail.price.price;
        return total;
      },
      slickImg() {
        if(window.innerWidth>500) {
          let width = 318;
          let height = 483;
          let margin = '0 6px';
          return {
            width: width+'px',
            height: height+'px',
            margin: margin,
            borderRadius: '8px'
          }
        } else {
          let width = window.innerWidth - 24.5;
          let height = width * 1.31;
          let padding = '0 6px';
          return {
            width: width+'px',
            height: height+'px',
            padding: padding,
            borderRadius: '8px'
          }
        }
      },
      contentSlideStyle() {
        if(window.innerWidth<500) {
          return {
            width: '100%',
            height: this.slickImg.height
          }
        } else {
          return {
            width: '1106px',
            height: this.slickImg.height
          }
        }
      }
    },
    methods: {
      clickInquiry() {
        const title = `${this.detail.name} 서비스 이용 문의`;
        const content = '부가서비스 문의 남깁니다.';

        this.$store.commit('setInquiry', {
          title: title,
          content: content
        });
        this.routerPush('/inquiry_reg?type_id=10');
      },
      next() {
        this.$refs.slick.next();
      },

      prev() {
        this.$refs.slick.prev();
      },
      // 상품 상세
      getProductDetail() {
        this.$axios.get(`public/launchpack/v1/theme/${this.$route.query.id}`).then(async res=>{
          if(res.status === 200) {
            this.detail = res.data;
          }
        });
      },
      imgField(img, device) {
        let style = {
          backgroundImage: `url(${this.image(img)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        };
        if(device==='mobile') {
           style.height = (window.innerWidth * 0.7).toString() +'px';
        }
        return style;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .product-wrapper
      display grid
      grid-gap 0 72px
      grid-template-columns repeat(2, 1fr)

    .card-img
      width 516px
      height 516px
      border-radius 8px

  .mobile
    .card-img
      width 100%
      border 1px solid $gray3


    .fixed-bottom
      border-top 1px solid $gray1
      padding 12px
      background-color white
      position fixed
      width 100%
      bottom 0
      left 0
      z-index 10


  .writeInquire
    text-align left
    margin-bottom 10px
    color #303030
    font-weight 500

  .writeTxt
    width 100%
    height 96px

  .writeTxt::-ms-input-placeholder
    text-align left

  .writeTxt::-webkit-input-placeholder
    text-align left

  .writeTxt::-moz-input-placeholder
    text-align left

  .preview-box
    width 216px
    height 48px
    border 1px solid $sub5
    color $sub2

  .preview-box:hover
    background-color $gray4

  .lp-divider-gray3
    border-bottom 12px solid $gray3

  .circle-primary-light
    width 88px
    height 88px
    background-color $primary-light2
    border-radius 100%
    display flex
    align-items center
    justify-content center
    img
      width 48px
      height 48px

  .guide-modal-content
    padding-right 22px
    height 454px
    overflow-y auto
    text-align left
    white-space pre-line
    word-break keep-all

  @media (max-width:1024px)
    .circle-primary-light
      width 44px
      height 44px
      img
        width 24px
        height 24px
    .guide-modal-content
      padding-right 8px
      height 387px

</style>

<style lang="stylus">
  @import '~assets/css/lp_main'
  .store-detail-guide-text:hover
    color $primary-dark

  .store-detail-guide-modal
    .sweet-modal.is-alert .sweet-content
      padding 40px 50px

  @media (max-width:1024px)
    .store-detail-guide-modal
      .sweet-modal.is-alert .sweet-content
        padding 48px 16px 24px 16px !important
    .tablet-preview
      display block
    .mobile-preview
      display none


  @media (max-width:640px)
    .tablet-preview
      display none
    .mobile-preview
      display block

</style>
